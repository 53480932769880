/* eslint-disable @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment, react/prop-types */
import { Fragment, ReactNode } from 'react';

import {
	useTheme,
	useMediaQuery,
	Box,
	Grid,
	TableContainer,
	Table as MuiTable,
	TableRow,
	TableCell,
	Typography,
	TypographyProps,
	TableBody,
	SxProps,
} from '@mui/material';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Inline, NodeData, Node, Text } from '@contentful/rich-text-types';

import FocalPointImage from '@/components/FocalPointImage/FocalPointImage';
import { HeadingTag, Maybe, Sizes, TableVariant } from '@/types';
import { Card, ContentTypeRichTextContent, Entry, Table as TableProps } from '@/types/generated';
import getHeadingVariant from '@/utils/resolveContentfulHeadingVariant';
import { textToJumpLink } from '@/utils';

import TemplateContainer from '../TemplateContainer';
import ContentTypeRichText from '../ContentTypeRichText';

import {
	CheckedIcon,
	CheckedIconDefault,
	XMarkIcon,
	XMarkIconDefault,
	getCompareTableHeader,
	hasPlaceHolders,
	placeHolderValues,
	removePlaceHolderValues,
} from './Table.utils';
import {
	compareVariantTCGridStyles,
	compareVariantTHCellGrid,
	compareVariantTHCellGridBox,
	compareVariantTHCellGridNestedBox,
	compareVariantTHCellGridParentBox,
	compareVariantTHCellImageStyles,
	compareVariantTHCellStyles,
	compareVariantTRBoxStyles,
	containerWrapperStyles,
	defaultVariantTableCellStyles,
	defaultVariantTableHeaderCellStyles,
	defaultVariantTableRowStyles,
	defaultVariantTableStyles,
	getHeadingStyles,
	mottoVariantTRDefaultColumnStyles,
	mottoVariantTRStickyColumnStyles,
	mottoVariantTableStyles,
	titleAndDescriptionCtrStyles,
} from './Table.styles';
import TableFromJson, { ITableFromJson } from './TableFromJson';

export interface ITable extends TableProps {
	/**
	 * Optional styles to apply to the wrapper container.
	 *
	 * @type {SxProps}
	 * @memberof IContentTypeRichText
	 */
	containerSx?: SxProps;
	titleHtag?: Maybe<HeadingTag>
	/**
	 * Optional json data to render the table.
	 *
	 * @type {SxProps}
	 * @memberof IContentTypeRichText
	 */
	tableJsonData?: ITableFromJson;
	/**
	 * Optional json data to render the table for mobile if different from desktop.
	 *
	 * @type {SxProps}
	 * @memberof IContentTypeRichText
	 */
	tableJsonDataMobile?: ITableFromJson;
}

export default function Table({
	border = true,
	fontSize = 'bodySmallBook',
	footnote,
	tableContent,
	variant = 'default',
	firstColumnSticky = true,
	containerSx = {},
	tableJsonData,
	tableJsonDataMobile,
	title,
	titleHtag = 'h1',
	descriptionRichText
}: Readonly<ITable>) {
	const theme = useTheme();
	const mobileWidth = useMediaQuery(theme.breakpoints.down('sm'));
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const jsonToRenderTable = isSmallScreen && tableJsonDataMobile ? tableJsonDataMobile : tableJsonData
	const renderTableFromJson = Boolean(jsonToRenderTable?.rows?.length);

	const getTableVariant = (variant: TableVariant) => {
		switch (variant) {
			case 'compare':
				return compareTableOptions;
			case 'mottoV2Compare':
				return mottoV2CompareTableOptions;
			case 'simple':
				return simpleTableOptions;
			default:
				return defaultTableOptions;
		}
	};

	interface ITableNodes {
		hasBorder: Maybe<boolean>;
		textAlign?: 'center' | 'left';
		isSmallHeader?: Maybe<boolean>;
		hasHeaderBackgroundColor?: Maybe<boolean>;
		cellSpacing?: Maybe<Omit<Sizes, 'XLarge'>>;
	}

	const getCellSpacing = (size: Sizes): string => {
		const sizes = {
			Small: '0.5',
			Medium: '1',
			Large: '1.5',
		} as { [key: string]: string };
		return `${sizes[size || 'Medium']}rem`;
	};

	const getTableNodes = ({
		hasBorder = true,
		textAlign = 'center',
		isSmallHeader = false,
		hasHeaderBackgroundColor = true,
		cellSpacing = 'Medium',
	}: Readonly<ITableNodes>) => {
		return {
			renderNode: {
				[BLOCKS.TABLE]: (_: unknown, children: ReactNode) => {
					return (
						<MuiTable sx={{ ...defaultVariantTableStyles(hasBorder, mobileWidth) }}>{children}</MuiTable>
					);
				},
				[BLOCKS.TABLE_ROW]: (_: unknown, children: ReactNode) => {
					return <TableRow sx={{ ...defaultVariantTableRowStyles(hasBorder) }}>{children}</TableRow>;
				},
				[BLOCKS.TABLE_CELL]: (_: NodeData) => {
					const placeholders = hasPlaceHolders(_.content[0]?.content);
					const fieldValue = removePlaceHolderValues(_.content[0]?.content[0].value);
					return (
						<TableCell
							align={textAlign}
							sx={{
								...defaultVariantTableCellStyles(placeholders, hasBorder, theme),
								padding: getCellSpacing(cellSpacing as Sizes),
							}}
						>
							<Typography variant={fontSize as TypographyProps['variant']} color="text.primary">
								{fieldValue}
							</Typography>
							{placeholders[placeHolderValues.CHECKED] ? (
								<CheckedIcon />
							) : placeholders[placeHolderValues.XMARK] ? (
								<XMarkIcon />
							) : null}
						</TableCell>
					);
				},
				[BLOCKS.TABLE_HEADER_CELL]: (_: NodeData) => {
					const placeholders = hasPlaceHolders(_.content[0]?.content);
					const fieldValue = removePlaceHolderValues(_.content[0]?.content[0].value);
					return (
						<TableCell
							align={textAlign}
							sx={{
								...defaultVariantTableHeaderCellStyles(
									placeholders,
									hasBorder,
									theme,
									hasHeaderBackgroundColor !== false
								),
								padding: getCellSpacing(cellSpacing as Sizes),
							}}
						>
							<Typography
								variant={isSmallHeader ? 'bodyLargeBold' : 'header4'}
								color={placeholders[placeHolderValues.HIGHLIGHTED] ? 'text.light' : 'text.primary'}
								sx={{
									margin: isSmallHeader ? '0' : '1.38rem 0',
								}}
							>
								{fieldValue}
							</Typography>
						</TableCell>
					);
				},
			},
		};
	};

	const defaultTableOptions = getTableNodes({
		hasBorder: border !== false,
	});

	const simpleTableOptions = getTableNodes({
		hasBorder: false,
		textAlign: 'left',
		isSmallHeader: true,
		hasHeaderBackgroundColor: false,
		cellSpacing: 'Small',
	});

	// TO DO: this one will be revisited
	const compareTableOptions = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (_: NodeData, children: ReactNode) => {
				const p: string = _?.content[0]?.value || '';
				const para: boolean = p.includes('row*title');

				return (
					<p
						style={{
							display: para ? 'none' : 'block',
							margin: '16px 0 0 0',
						}}
					>
						<Typography variant="bodyLargeBook" color="text.secondary">
							{children as string}
						</Typography>
					</p>
				);
			},
			[BLOCKS.TABLE]: (_: NodeData, children: ReactNode) => {
				return <MuiTable>{children}</MuiTable>;
			},
			[BLOCKS.TABLE_ROW]: (_: NodeData, children: ReactNode) => {
				const rt: string = _.content[0]?.content[0]?.content[0]?.value;
				let rowTitle!: string;
				let titleRegular!: string;
				let titleBold!: string;
				if (rt.includes('row*title')) {
					const t = rt.split('*');
					rowTitle = rt.split('*')[t.length - 1];
					const indexOfOpeningP: number = rowTitle.indexOf('(');
					const indexOfClosingP: number = rowTitle.indexOf(')');
					titleRegular = rowTitle.substring(indexOfOpeningP, indexOfClosingP + 1);
					titleBold = rowTitle.replace(titleRegular, '');
				}

				return (
					<Grid container spacing={2} wrap="nowrap" justifyContent="space-between" position="relative">
						{rowTitle && (
							<Box sx={compareVariantTRBoxStyles}>
								<Typography variant="bodyLargeBold" color="text.primary">
									{titleBold}
								</Typography>

								{titleRegular && (
									<Typography variant="bodyLargeBook" color="text.primary">
										{titleRegular}
									</Typography>
								)}
							</Box>
						)}
						{children}
					</Grid>
				);
			},
			[BLOCKS.TABLE_CELL]: (_: NodeData, children: ReactNode) => {
				if (_.content[0].content.find((el: Inline) => el.nodeType == 'embedded-entry-inline')) {
					const ar = _.content[0].content.filter((el: Inline) => el.nodeType == 'embedded-entry-inline');

					return (
						<Grid item sm={4} md={4} sx={{ ...compareVariantTCGridStyles(mobileWidth) }}>
							{ar.map((elem: Node, index: number) => {
								/* TO DO: BE needs to add support for services in facility graph
                // then below logic can be test */
								// const price = officeInfo?.services?.find(
								//   el => el.procedureCode == elem.data.target.procedureCode,
								// )?.ucfPrice

								return (
									<Fragment key={index}>
										<Typography variant="bodySmallBook" color="text.primary">
											{elem.data.target.title as string}
										</Typography>
										{/* <Typography variant="bodySmallBook" color="text.primary">
                      From ${price as unknown as string}
                    </Typography> */}
									</Fragment>
								);
							})}
						</Grid>
					);
				}

				const isPlaceHolderXMark =
					_?.content[0]?.content[0]?.value == placeHolderValues.XMARK ||
					_?.content[1]?.content[0]?.value == placeHolderValues.XMARK;
				const isPlaceHolderChecked =
					_?.content[0]?.content[0]?.value == placeHolderValues.CHECKED ||
					_?.content[1]?.content[0]?.value == placeHolderValues.CHECKED;

				const mark = isPlaceHolderXMark
					? placeHolderValues.XMARK
					: isPlaceHolderChecked
					? placeHolderValues.CHECKED
					: null;

				return (
					<Grid item sm={4} md={4} sx={{ ...compareVariantTCGridStyles(mobileWidth) }}>
						{mark == placeHolderValues.CHECKED ? (
							<CheckedIcon />
						) : mark == placeHolderValues.XMARK ? (
							<XMarkIcon />
						) : (
							children
						)}
					</Grid>
				);
			},
			[BLOCKS.TABLE_HEADER_CELL]: (node: NodeData) => {
				const title = getCompareTableHeader(node.content[0].content);

				const target = node?.content[0];
				const inlineEntryContent = target?.content?.filter(
					(content: Node) => content.nodeType === 'embedded-entry-inline'
				);
				const targetSysId = inlineEntryContent[0]?.data?.target?.sys?.id;
				const inlineEntries = tableContent?.links && tableContent.links.entries.inline;
				const matchingInlineEntry = inlineEntries?.find((entry: Maybe<Entry>) => entry?.sys.id === targetSysId);

				return (
					<Grid item sm={4} md={4} sx={compareVariantTHCellGrid}>
						<Box sx={{ ...compareVariantTHCellGridBox(theme) }}>
							<Typography variant="bodySmallBook" color="text.primary">
								{title}
							</Typography>

							{matchingInlineEntry && (
								<Box sx={compareVariantTHCellGridParentBox}>
									<Box
										sx={{ ...compareVariantTHCellGridNestedBox(theme) }}
										data-test-id={`table_header_image_box_${matchingInlineEntry?.sys.id}`}
									>
										{(matchingInlineEntry as Card)?.image && (
											<FocalPointImage image={(matchingInlineEntry as Card)?.image} />
										)}
									</Box>
								</Box>
							)}
						</Box>
					</Grid>
				);
			},
		},
	};

	const mottoV2CompareTableOptions = {
		renderNode: {
			[BLOCKS.TABLE]: (_: NodeData, children: ReactNode) => {
				return (
					<MuiTable sx={mottoVariantTableStyles}>
						<TableBody>{children}</TableBody>
					</MuiTable>
				);
			},
			[BLOCKS.TABLE_ROW]: (_: NodeData, children: ReactNode) => {
				const tdStyles = firstColumnSticky ?? true ? { ...mottoVariantTRStickyColumnStyles(theme) } : {};
				return (
					<TableRow
						sx={{
							...tdStyles,
							...mottoVariantTRDefaultColumnStyles(theme),
						}}
					>
						{children}
					</TableRow>
				);
			},
			[BLOCKS.TABLE_CELL]: (_: NodeData) => {
				const placeholders = hasPlaceHolders(_.content[0]?.content);
				const fieldValue = removePlaceHolderValues(_.content[0]?.content[0].value);

				return (
					<TableCell align="left" sx={{ ...compareVariantTHCellStyles(placeholders, theme) }}>
						{fieldValue}
						{placeholders[placeHolderValues.CHECKED] ? (
							<CheckedIconDefault />
						) : placeholders[placeHolderValues.XMARK] ? (
							<XMarkIconDefault />
						) : null}
					</TableCell>
				);
			},
			[BLOCKS.TABLE_HEADER_CELL]: (_: NodeData) => {
				const placeholders = hasPlaceHolders(_.content[0]?.content);
				const img = _.content[0]?.content.find((el: Inline) => el.nodeType == 'embedded-entry-inline')?.data
					.target.image;
				let fieldValue = _.content[0]?.content.filter((el: Inline) => el.nodeType != 'embedded-entry-inline');
				fieldValue = fieldValue.find((e: Text) => e.value != '');
				if (fieldValue == undefined) {
					fieldValue = '';
				} else {
					fieldValue = removePlaceHolderValues(fieldValue.value);
				}

				return (
					<TableCell
						align="left"
						sx={{
							backgroundColor: placeholders[placeHolderValues.HIGHLIGHTED]
								? theme.palette.backgrounds?.light
								: '',
						}}
					>
						{img && (
							<Box sx={compareVariantTHCellImageStyles}>
								<FocalPointImage image={img} />
							</Box>
						)}
						<Typography variant="header4" color="text.primary">
							{fieldValue as string}
						</Typography>
					</TableCell>
				);
			},
		},
	};

	return (
		<TemplateContainer
			dataTestId="section_table_container"
			containerSx={{ ...containerWrapperStyles(containerSx, renderTableFromJson) }}
			maxWidth={renderTableFromJson ? false : 'lg'}
		>
			<Box
				sx={titleAndDescriptionCtrStyles}
			>
					{title && (
						<Typography
							variant={getHeadingVariant(titleHtag)}
							id={textToJumpLink(title)}
							component={
								titleHtag === 'normal'
									? 'p'
									: titleHtag ?? 'h1'
							}
							sx={{
								...getHeadingStyles(theme),
							}}
							whiteSpace="pre-line"
							data-test-id="section_table_title"
						>
							{title}
						</Typography>
					)}
					{descriptionRichText && (
						<ContentTypeRichText
							content={descriptionRichText as ContentTypeRichTextContent}
							dataTestId={`section_table_description_rich_text`}
							backgroundColor="transparent"
							disablePaddingOn={['Top', 'Bottom']}
							contentMaxWidth="100%"
							docProps={{
								variant: isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook',
								textAlign: 'center'
							}}
						/>
					)}
			</Box>
			
			{renderTableFromJson ? (
				<TableFromJson jsonData={jsonToRenderTable} firstColumnSticky={firstColumnSticky}/>
			) : (
				<TableContainer>
					{documentToReactComponents(tableContent?.json, getTableVariant(variant as TableVariant))}
				</TableContainer>
			)}
			{footnote?.json && (
				<ContentTypeRichText
					dataTestId="section_table_footnote"
					content={footnote}
					disableGutters
					disablePaddingOn={['Top', 'Bottom']}
				/>
			)}
		</TemplateContainer>
	);
}
